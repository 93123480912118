/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import Container from '../../components/Container';
import Image from '../../components/Image';
import {
  BodyTextBig,
  FlexWrap,
  SectionTitle,
  GraySection,
  WhiteSection,
} from '../../components/Global';
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';
import constants from '../../constants';

const FeaturesWrap = styled(FlexWrap)`
  width: 900px;
  flex-wrap: wrap;
  ${MediaBreakDown.lg`
    max-width: 400px;
    width: 100%;
  `}
`;

const FeatureItem = styled.div`
  padding: 20px;
  width: 181px;
  color: ${theme.textColor.blackText};
  .gatsby-image-wrapper {
    width: 87px;
    height: 87px;
    margin: auto;
  }
  :hover {
    box-shadow: 0px 4px 10px ${theme.boxShadow.light};
    border-radius: 6px;
    cursor: pointer;
  }
  @media(max-width: 400px) {
    width: 150px;
    padding: 15px;
  }
`;

const features = [
  {
    name: 'Custom Lists',
    image: 'custom-list.png',
    link: '/help/articles/61000165174/'
  },
  {
    name: 'Subtasks',
    image: 'sub-tasks.png',
    link: '/help/articles/61000165154/'
  },
  {
    name: 'Custom tags',
    image: 'custom-tags.png',
    link: '/help/articles/61000165192/'
  },
  {
    name: 'Guest users',
    image: 'guest-users.png',
    link: '/help/articles/61000165258/'
  },
  {
    name: 'Attachments',
    image: 'attachments.png',
    link: '/help/articles/61000165115/'
  },
  {
    name: 'Followers',
    image: 'followers.png',
    link: '/help/articles/61000165216/'
  },
  {
    name: 'Calendar view on Space',
    image: 'callendar.png',
    link: '/help/articles/61000165108/'
  },
  {
    name: 'Clone tasks',
    image: 'clone_tasks.png',
    link: '/help/articles/61000165141/'
  },
  {
    name: 'Recurring due dates ',
    image: 'recurring-due-date.png',
    link: '/help/articles/61000165205/'
  },
  {
    name: 'Export reports to CSV',
    image: 'csv.png',
    link: '/help/articles/61000165222/'
  },
  {
    name: 'Comments on tasks ',
    image: 'comments.png',
    link: '/help/articles/61000165259/'
  },
  {
    name: 'Public and private forms',
    image: 'public-private-form.png',
    link: '/help/articles/61000165244/'
  },
  {
    name: 'Import tasks from CSV and Trello',
    image: 'trello.png',
    link: '/help/categories/61000063757/'
  },
  {
    name: 'Create tasks through email',
    image: 'feature_email.png',
    link: '/help/articles/61000165246/'
  },
  {
    name: 'Public and private projects ',
    image: 'public-private-project.png',
    link: '/help/articles/61000273674/'
  },
  {
    name: 'Mobile app: iOS and Android',
    image: 'mobile-app.png',
    link: '/help/categories/61000080939/'
  }
];

const AllFeaturesSection = ({
  backgroundGray,
}) => {
  const content = (
    <Container>
      <SectionTitle mb="15px">
        All features
      </SectionTitle>
      <BodyTextBig maxWidth="615px" mb="40px">
        Learn more about the features that Workast offers here:
      </BodyTextBig>
      <FeaturesWrap>
        {
          features.map(feature => (
            <Link to={feature.link} key={feature.name}>
              <FeatureItem>
                <Image name={feature.image} alt={feature.name} />
                <BodyTextBig mb="0px">
                  {feature.name}
                </BodyTextBig>
              </FeatureItem>
            </Link>
          ))
        }
      </FeaturesWrap>
    </Container>
  );

  if (backgroundGray) {
    return (
      <GraySection
        id={constants.DOM_ID.ALL_FEATURES}
        className="centered-text"
      >
        {content}
      </GraySection>
    );
  }

  return (
    <WhiteSection
      id={constants.DOM_ID.ALL_FEATURES}
      className="centered-text no-padding-bottom"
    >
      {content}
    </WhiteSection>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
AllFeaturesSection.defaultProps = {
  backgroundGray: false,
};
AllFeaturesSection.propTypes = {
  backgroundGray: PropTypes.bool,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default AllFeaturesSection;
