import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';


let imageData = null;

function Image(props) {
  // This is to prevent querying all the images and showing only one:
  if (!imageData) {
    imageData = useStaticQuery(
      graphql`query imageQuery {
        allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
    }`
    );
  }
  
  const {
    name,
    alt,
    maxWidth,
    borderRadius,
    margin
  } = props;

  // 1. First I look the image with the exact name:
  let image = imageData.allFile.edges.find(
    img => img.node.relativePath === name
  );

  // 2. If it's null, then we perform the old search:
  if (!image) {
    image = imageData.allFile.edges.find(
      img => img.node.relativePath.includes(name)
    );
  }

  return (
    <GatsbyImage
      alt={alt}
      image={getImage(image.node.childImageSharp.gatsbyImageData)}
      style={{
        maxWidth,
        margin,
        borderRadius
      }}
    />
  );
}

Image.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  margin: PropTypes.string,
};

Image.defaultProps = {
  maxWidth: '1600px',
  borderRadius: '0px',
  margin: 'auto',
};

export default Image;
