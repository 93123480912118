const TRIAL = {
  LINK_TEXT: 'Start 14 day trial',
  LINK_TEXT_HEADER: 'Start your 14 day trial',
  LINK_URL: '/trial/start',
};

const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const DOM_ID = {
  SPACES: 'spaces',
  TASKS: 'tasks',
  CUSTOM_FIELDS: 'custom_fields',
  BOARD: 'board',
  CALENDAR: 'calendar',
  REPORTS: 'reports',
  FORMS: 'forms',
  HOME: 'home',
  NOTES: 'notes',
  TEMPLATES: 'templates',
  MEETINGS: 'meetings',
  EXTENSIONS: 'extensions',
  ALL_FEATURES: 'all-features',
};

const URLS = {
  AFFILIATE: 'https://hq.workast.com/form/6064a43eed196a0007a3a021#/',
  CONTACT_SALES: 'https://hq.workast.com/form/5ee3b07714868b00076df32d#/',
  HELP_ARTICLE_BOARD: '/help/article/using-the-board-view/',
  HELP_ARTICLE_CALENDAR: '/help/article/calendar-sync-extension/',
  HELP_ARTICLE_CREATE_PROJECT: '/help/article/how-to-create-a-project-or-event/',
  HELP_ARTICLE_CUSTOM_FIELDS: '/help/article/custom-fields-in-workast/',
  HELP_ARTICLE_FILE_TO_TASK: '/help/article/how-to-add-an-attachment-or-file-to-a-task/',
  HELP_ARTICLE_FORMS: '/help/article/creating-and-using-forms-in-workast/',
  HELP_ARTICLE_MOBILE_APP: '/help/category/mobile-app/',
  HELP_ARTICLE_NOTES: '/help/article/the-notes-extension-101/',
  HELP_ARTICLE_TIMELINE: '/help/article/the-timeline-view/',
};

export default {
  TRIAL,
  SIZES,
  DOM_ID,
  URLS,
};
